<script>
import CONFIG from '@root/config'

export default {
    name: 'PricingHelperMethods',
    data () {
        return {
            tasks: [],
            variations: [],
        }
    },
    methods: {
        getShops (agreementId) {
            if (!this.isAllowedTo('getAgreementShopsByAgreementId')) {
                return
            }
            const url = CONFIG.API.ROUTES.AGREEMENTS.GET_APPLICATIONS.replace('{agreementId}', agreementId)

            this.addJob(url)
            return new Promise((resolve, reject) => {
                this.$api.get(url).then(({ data }) => {
                    resolve(data)
                }).catch(err => {
                    this.$root.$emit('notification:global', {
                        message: err,
                        type: 'error',
                    })
                    reject(err)
                }).finally(() => {
                    this.finishJob(url)
                })
            })
        },
        listPricingRules (templateId, filterModel = null) {
            const url = CONFIG.API.ROUTES.PRICING.PRICING_RULES.LIST.replace('{pricingRuleTemplateId}', templateId)
            const payload = JSON.parse(JSON.stringify(CONFIG.API.REQUEST_DEFAULT))
            payload.request.sortModel = [{ colId: 'importance', sort: 'asc' }]
            if (filterModel) {
                payload.request.filterModel = filterModel
            }
            this.addJob(url)
            return new Promise((resolve, reject) => {
                this.$api.post(url, payload).then(({ data }) => {
                    resolve(this.transformMinGpmToPercentage(data.data.items))
                }).catch((err) => {
                    this.$root.$emit('notification:global', {
                        message: 'Something went wrong fetching pricing rules',
                        type: 'error',
                        errors: err,
                    })
                }).finally(() => {
                    this.finishJob(url)
                })
            })
        },
        transformMinGpmToPercentage (items) {
            return items.map((item) => this.convertMinGpm(item))
        },
        convertMinGpm (item) {
            if (item.minGpm !== null) {
                item.minGpm = +(item.minGpm * 100).toFixed(2)
            }
            return item
        },
    },
}
</script>
