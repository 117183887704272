<template>
    <div class="task">
        <div class="task__title">
            <span>Task {{ index }}</span>
            <status-chip>{{ task.taskStatus }}</status-chip>
            <mercur-button @click="isOpen = !isOpen" class="btn btn-icon ml-1">
                <i class="fas" :class="isOpen ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
            </mercur-button>
        </div>
        <div class="task__content border-bottom" v-if="isOpen">
            <ul class="task__info">
                <li><strong>Product Hash:</strong><br> {{ task.productHash }}</li>
                <li><strong>Product Template Id:</strong><br> {{ task.productTemplateId }}</li>
                <li><strong>Product Category Id:</strong><br> {{ task.productCategoryId }}</li>
                <li v-for="(value, key) in statuses" :key="key"><strong>{{ key }} TASKS: </strong>{{ value }}</li>
            </ul>
            <div class="task__filters mb-10">
                <mercur-chip v-for="status in possibleStatuses" :clickable="true" :key="status" class="mr-5" :class="{'bg-blue': filter === status}" @click="setFilter(status)">{{ status }}</mercur-chip>
            </div>
            <mercur-spinner v-if="loading"></mercur-spinner>
            <div v-else-if="!loading && variationsList.length">
                <div class="task__variation" v-for="(variation, index) in variationsList" :key="index">
                    <div><strong>SKU:</strong> {{ variation.sku }}</div>
                    <div class="flex">
                        <p class="flex-1 m-0"><strong>STATUS:</strong> {{ variation.variationStatus }}</p>
                        <p class="m-0 task__variation-detail" @click="showErrors(variation)" v-if="variation.variationStatus === 'FAILED'">View details</p>
                    </div>
                </div>
            </div>
            <div v-else>There are no variations for this task</div>
        </div>
        <mercur-dialog :is-open.sync="isOpenErrorsDialog">
            <template slot="default">
                <h2 class="font-weight-normal">Variation errors</h2>
                <div v-if="draftVariation.result && draftVariation.result.errors">
                    <div class="dialog-error" v-for="(error, sku) in draftVariation.result.errors" :key="sku">
                        <p class="dialog-error__sku"><strong>ID and SKU: </strong>{{ sku }}</p>
                        <p class="dialog-error__message">{{ error.message}}!</p>
                        <div class="dialog-error__suberror" v-for="(subError, index) in error.subErrors" :key="`${sku}-${index}`">
                            <pre>{{ subError }}</pre>
                        </div>
                    </div>
                </div>
                <p v-else>There are no errors for this variation</p>
            </template>
        </mercur-dialog>
    </div>
</template>

<script>
import PricingHelperMethods from './PricingHelperMethods'
import StatusChip from '../utils/StatusChip'
export default {
    name: 'TemplateTask',
    mixins: [PricingHelperMethods],
    components: { StatusChip },
    props: {
        task: {
            required: true,
        },
        index: {},
    },
    data () {
        return {
            loading: false,
            isOpen: false,
            statuses: null,
            filter: null,
            possibleStatuses: ['SUCCEEDED', 'FAILED', 'WARNING'],
            isOpenErrorsDialog: false,
            draftVariation: {},
        }
    },
    watch: {
        isOpen (value) {
            if (!value) {
                return
            }

            this.loading = true
            this.showVariations(this.task.salesPriceTaskId, null, true).then((data) => {
                if (data.data.statuses) {
                    this.statuses = data.data.statuses
                }
            }).finally(() => {
                this.loading = false
            })
        },
    },
    computed: {
        variationsList () {
            if (!this.filter) {
                return this.variations
            }

            return this.variations.filter(e => e.variationStatus === this.filter)
        },
    },
    methods: {
        setFilter (value) {
            if (this.filter === value) {
                this.$set(this, 'filter', null)
                return
            }

            this.$set(this, 'filter', value)
        },
        showErrors (variation) {
            this.$set(this, 'draftVariation', variation)
            this.isOpenErrorsDialog = true
        },
    },
}
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/_variables.scss';
    .task {
        margin: 10px 10px 0;

        &__title {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #fafafa;
            padding-bottom: 10px;
            span {
                flex: 1;
                display: flex;
                align-items: center;
                font-size: 14px;
            }

            .btn-icon {
                height: unset;
                width: unset;
                i {
                    font-size: 12px !important;
                }
            }
        }

        &__content {
            padding: 10px 0;
            border-radius: 4px;
            font-size: 11px;
        }

        &__variation {
            border: 1px solid rgba(0,0,0,0.12);
            margin-bottom: 5px;
            padding: 5px;

            &-detail {
                cursor: pointer;
                color: $light-blue-color;
            }
        }

        &__info {
            list-style: none;
            margin-top: 0;
            border: 1px solid rgba(0,0,0,0.3);
            background-color: rgba(0,0,0,0.02);
            padding: 5px;

            li {
                word-break: break-all;
            }
        }

        &__filters {
            .chip {
                width: 95px;
                text-align: center;

                .md-clickable>.md-ripple {
                    padding: 0;
                }
            }
        }
    }

    .dialog-error {
        border: 1px solid rgba(0,0,0,0.12);
        padding: 10px;
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 16px;

        &__sku {
            max-width: 800px;
            word-break: break-all;
            margin-bottom: 0;
        }

        &__message {
            color: red;
            margin: 0;
            font-weight: 500;
        }

        &__suberror {
            background-color: rgba(0,0,0,0.02);
            border: 1px solid rgba(0,0,0,0.06);
            padding: 5px 10px;
            margin-top: 5px;
            font-size: 11px;
        }
    }
</style>
