<template>
    <div v-if="isAllowedTo('SupplierCentral/listSalesPriceTasks')" class="sidebar-detail">
        <div class="d-flex align-items-center p-3 border-bottom">
            <mercur-button class="btn btn-icon mr-1" :to="parentRoute"><i class="fas fa-times"></i></mercur-button>
            <span class="flex-grow-1">List of tasks <span v-if="templateName">for {{ templateName }}</span></span>
        </div>
        <div class="sidebar-detail__content full-height-layout">
            <div class="sidebar-detail__spinner" v-if="loading">
                <mercur-spinner></mercur-spinner>
            </div>
            <div v-else-if="tasks.length" class="fhf">
                <template-task v-for="(task, index) in tasks" :task="task" :index="index + 1" :key="task.salesPriceTaskId">Task {{ index + 1 }}</template-task>
            </div>
            <div v-else>
                <p>There seem to be no new tasks. Please <a @click.prevent="listSalesPriceTasks()">try again</a></p>
            </div>
        </div>
    </div>
    <div v-else>
        <p class="permission-message">Not allowed to see this view</p>
    </div>
</template>
<script>
import TemplateTask from '../../components/pricing/TemplateTask'
import PricingHelperMethods from '../../components/pricing/PricingHelperMethods'
import CONFIG from '@root/config'

export default {
    name: 'TemplateTasks',
    mixins: [PricingHelperMethods],
    components: { TemplateTask },
    props: {
        templateName: {
            default: null,
        },
    },
    data () {
        return {
            loading: false,
            tasks: [],
        }
    },
    watch: {
        '$route.params.pricingRuleTemplateId' (value) {
            if (!value) {
                return
            }
            this.listLatestSalesPriceTasks()
        },
    },
    computed: {
        parentRoute () {
            return {
                name: 'PricingRuleTemplates',
                params: {
                    agreementId: this.$route.params.agreementId,
                },
            }
        },
    },
    methods: {
        listLatestSalesPriceTasks () {
            this.loading = true
            const url = CONFIG.API.ROUTES.PRICING.TASKS.LIST_LATEST
            const payload = JSON.parse(JSON.stringify(CONFIG.API.REQUEST_DEFAULT))
            payload.request.filterModel.pricingRuleTemplateId = {
                filter: this.$route.params.pricingRuleTemplateId,
                filterType: 'text',
                type: 'equals',
            }
            this.addJob(url)
            this.$api.post(url, payload).then(({ data }) => {
                this.$set(this, 'tasks', data.data.items)
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong fetching template tasks',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.finishJob(url)
                this.loading = false
            })
        },
    },
    created () {
        this.listLatestSalesPriceTasks()
    },
}
</script>
