<template>
    <div data-test="statusChipWrapper" class="chip" :class="classNames" v-if="value"><slot>{{text}}</slot></div>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
    name: 'StatusChip',
    data () {
        return {
            styleMap: {
                red: ['error', 'failed', 'fail', 'pricing_rules_missing'],
                orange: ['warning', 'caution', 'processing_file', 'awaiting_approval', 'queued_for_processing', 'queued_for_generation', 'generating_variations', 'done_with_errors'],
                green: ['success', 'complete', 'completed', 'done', 'finished', 'active', 'submitted', 'published', 'valid', 'awaiting_publication', 'approved', 'delivered'],
                blue: ['pending', 'in progress', 'waiting', 'running', 'in-progress', 'processing', 'approval_needed', 'running', 'full', 'partial'],
                grey: ['inactive', 'unpublished', 'revoked', 'declined'],
            },
        }
    },
    computed: {
        value () {
            if (typeof this.params !== 'undefined') {
                return this.params.valueFormatted || this.params.value
            }
            if (this.$slots.default && this.$slots.default[0] && this.$slots.default[0].text) {
                return this.$slots.default[0].text.trim() || ''
            }
            return ''
        },
        text () {
            if (typeof this.params !== 'undefined' && this.params.text) {
                return this.params.text
            }
            return this.value
        },
        classNames () {
            const classNames = []
            if (this.params && this.params.statusChipSize) {
                classNames.push(this.params.statusChipSize)
            }
            const sourceText = (this.value || '').toLowerCase()

            Object.entries(this.styleMap).some(([color, texts]) => {
                return texts.some(text => {
                    if (sourceText === text) {
                        classNames.push(color)

                        return true
                    }
                })
            })

            if (classNames.length > 0) {
                return classNames
            }

            Object.entries(this.styleMap).some(([color, texts]) => {
                return texts.some(text => {
                    if (sourceText.includes(text)) {
                        classNames.push(color)

                        return true
                    }
                })
            })

            return (classNames.length ? classNames : ['orange'])
        },
    },
})
</script>

<style lang="scss" scoped>
.chip {
    border-radius: 20px;
    color: white !important;

    &.red {
        background-color: #f33a3a;
    }

    &.green {
        background-color: #379040;
    }

    &.yellow,
    &.orange {
        background-color: #ff8431;
    }

    &.blue {
        background-color: #5867d4;
    }

    &.grey {
        background-color: #aaaaaa;
    }

    &.small {
        height: 20px;
        line-height: 21px;
        padding-left: 6px;
        padding-right: 6px;
        font-size: 11px;
    }
}
</style>
